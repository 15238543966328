import {
  getVideoParts as _getVideoParts,
  getVideoDetail as _getVideoDetail,
  getVideoPage as _getVideoPage,
  comments as _comments,
  checkLike as _checkLike,
  like as _like,
  unlike as _unlike,
  comment as _comment,
  starts as _starts,
  start as _start,
  timeMeter
} from "@api";
import { reactive, onBeforeUnmount, ref } from 'vue'
import { getRealMedialUrl } from '@/util'

import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
export default function(initVideo, player, props, videoDom, domStr) {
  const route = useRoute();
  const router = useRouter();
  const timer = ref(null)
  const listenerTimer = ref(0);
  const uuid = ref('')
  const state = reactive({
    id: route.params.id,
    page: 0,
    video: {},
    rate: 0,
    parts: [],
    currentPart: 0,
    recommend: [],
    loading: false,
    isLiving: false,
    liked: false,
    words: "",
    commentList: [],
    classList: [],
    uname: localStorage.getItem('uname'),
    mask: false,
    current: 1,
    isMobile: window.innerWidth < 767

  });

  // onMounted(() => {
  // if (!localStorage.getItem('uname')) {
  //   timer.value = setInterval(() => {
  //     // console.log(1)
  //     if (player.value) {
  //       // eslint-disable-next-line no-debugger
  //       // debugger
  //       const duration = player.value.duration()
  //       const currentTime = player.value.currentTime()
  //       if ((duration > 600 && currentTime > 5 * 60) || (duration < 600 && currentTime > 2 * 60)) [
  //         player.value.pause(),
  //         state.mask = true
  //       ]
  //     }
  //   }, 1000)
  
  //   onBeforeUnmount(() => {
  //     clearInterval(timer.value)
  //   })
  // }

  const init = () => {
    state.id = route.params.id;
    state.currentPart = 0;
    state.page = 0;
    state.isLiving = false;
    state.video = {};
    state.recommend = [];
    uuid.value = null
    Promise.all([getVideoParts(true), getVideoDetail()]).then(() => {
      state.loading = false;
      try {
        player.value && player.value.dispose();
        videoDom.value.innerHTML = domStr;
        // eslint-disable-next-line no-empty
      } catch (error) {}
      state.parts[0] && initVideo(getRealMedialUrl(state.parts[0].url));
      getRecPage();
      comments();
      checkLike();
      starts();
      state.classList = buildClassTree();
    });
  };
  const getVideoParts = (refresh) => {
    return _getVideoParts({
      page: refresh ? (state.page = 0) : state.page,
      limit: 1000,
      id: state.id,
    }).then((res) => {
      state.parts = res.data.content;
    });
  };
  const getVideoDetail = () => {
    return _getVideoDetail(state.id).then((res) => {
      state.video = res.data;
      document.title = res.data.name
    });
  };
  const changePlay = (i, index) => {
    state.currentPart = index;
    initVideo(getRealMedialUrl(i.url),
      () => player.value.play()
    );
  };
  const getRecPage = () => {
    const len = state.video.categories && state.video.categories.length;
    if (len) {
      const flag = Math.floor(Math.random() * len);
      _getVideoPage({
        type: state.video.mediaType === "VIDEO" ? 1 : 2,
        categoryId: state.video.categories[flag].id,
        currentPage: 0,
        pageSize: 10,
      }).then((res) => {
        state.recommend = res.data.content
          .filter((i, index) => i.id !== state.video.id && index !== 10)
          .sort(() => Math.random() - 0.5)
          .slice(0, 5);
      });
    }
  };
  const comments = () => {
    _comments(state.id).then((res) => {
      state.commentList = res.data;
    });
  };
  const checkLike = () => {
    if (state.uname) {
      _checkLike(state.id).then((res) => {
        state.liked = res.data;
      });
    }
  };
  const like = () => {
    if (state.uname) {
      if (state.liked) {
        _unlike(state.id).finally(() => {
          checkLike();
        });
      } else {
        _like({ videoId: state.id }).finally(() => {
          checkLike();
        });
      }
    } else {
      router.push("/login");
    }
  };
  const goVideo = (v) => {
    router.push(`/${route.name}/${v.id}`);
  };
  const goVideos = (index) => {
    let url = "/videos";
    for (let i = 0; i <= index; i++) {
      url += "/" + state.classList[i].id;
    }
    router.push(url);
  };
  const comment = (context, id) => {
    return _comment({
      context: context || state.words,
      replyId: id,
      mediaId: state.id,
    })
      .then(() => {
        state.words = "";
      })
      .finally(() => {
        comments();
      });
  };
  const starts = () => {
    if (state.uname) {
      _starts(state.id).then((res) => {
        state.rate = res.data?.star;
      });
    }
  };
  const start = () => {
    _start({
      mediaId: state.id,
      star: state.rate,
    }).finally(() => {
      starts();
    });
  };
  const buildClassTree = () => {
    const currentClass = state.video.categories[0];
    if (currentClass) {
      let currentClassId = currentClass.id;
      const classTree = [currentClass];
      const pool = Object.values(props.menuIdMap);
      while (currentClassId) {
        let cur = pool.find(
          (i) =>
            i.children && i.children.includes(props.menuIdMap[currentClassId])
        );
        if (cur) {
          classTree.unshift(cur);
          currentClassId = cur.id;
        } else {
          currentClassId = 0;
        }
      }
      return classTree;
    }
  };

  const startListener = () => {
    state.uname && timeMeter({
      vid: state.id
    }).then(res => {
      uuid.value = res.data
      listenerTimer.value = setInterval(() => {
        timeMeter({
          vid: state.id,
          uuid: uuid.value
        })
      }, 10000)
    })
  }

  const endListener = () => {
    clearInterval(listenerTimer.value)
    uuid.value && timeMeter({
      vid: state.id,
      uuid: uuid.value
    })
    uuid.value = null
  }

  onBeforeUnmount(() => {
    endListener()
  })

  init();
  onBeforeRouteUpdate(() => {
    endListener()
    setTimeout(init)
  });
  return {
    state,
    goVideos,
    changePlay,
    like,
    start,
    comment,
    goVideo,
    startListener,
    endListener
  };
}
